/** подсчет фильтров используемые в компоненте */
export function getCounterFilters (getComponents, filters) {
  let result = null
  // получить компоненты у которых указаны дефолтное значения или компоненты из карточки с атрибутами
  const componentsWithDefaultValue = getComponents().filter(item => {
    return item.properties?.defaultValue || item.group === 'registry'
  })

  if (filters) {
    // подсчитать количество фильтров, которые имеют дефолтное значение связанные с компонентом(countFilters)
    let asyncFilters = componentsWithDefaultValue.filter(component =>
      filters.some(filterItem => filterItem.attribute === component.properties.name)
    ).length || 0
    let sumFilters = filters.reduce((acc, filterItem) => {
      if (filterItem.type === 'constant') {
        acc['constant'] += 1
      }
      if (filterItem.type === 'current_user') {
        acc['user'] += 1
      }
      if (filterItem.type === 'registry_field') {
        acc['field'] += 1
      }
      return acc
    }, { constant: 0, user: 0, field: 0, asyncFilters })

    result = asyncFilters + sumFilters.constant + sumFilters.user + sumFilters.field

    return result
  }

  return result
}
